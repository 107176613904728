%h1 {
  font-weight: bold;
  @include font-size(rem(28), rem(34));

  @include breakpoint(sm) {
    @include font-size(rem(34), rem(41));
  }

  @include breakpoint(lg) {
    @include font-size(rem(42), rem(51));
  }

  @include breakpoint(xxl) {
    @include font-size(rem(50), rem(57));
  }
}

%h2 {
  font-weight: bold;
  @include font-size(rem(26), rem(31));

  @include breakpoint(sm) {
    @include font-size(rem(34), rem(44));
  }

  @include breakpoint(lg) {
    @include font-size(rem(42), rem(50));
  }

  @include breakpoint(xxl) {
    @include font-size(rem(46), rem(52));
  }
}

%h3 {
  font-weight: 600;
  @include font-size(rem(20), rem(25));

  @include breakpoint(sm) {
    @include font-size(rem(24), rem(31));
  }

  @include breakpoint(lg) {
    @include font-size(rem(28), rem(38));
  }

  @include breakpoint(xxl) {
    @include font-size(rem(30), rem(36));
  }
}

%h4 {
  font-weight: 600;
  @include font-size(rem(18), rem(22));

  @include breakpoint(sm) {
    @include font-size(rem(18), rem(24));
  }

  @include breakpoint(lg) {
    @include font-size(rem(20), rem(26));
  }

  @include breakpoint(xxl) {
    @include font-size(rem(22), rem(28));
  }
}

%h5 {
  font-weight: 600;
  @include font-size(rem(16), rem(22));

  @include breakpoint(lg) {
    @include font-size(rem(18), rem(24));
  }

  @include breakpoint(xxl) {
    @include font-size(rem(20), rem(26));
  }
}

%h6 {
  font-weight: 600;
  @include font-size(rem(14), rem(20));

  @include breakpoint(lg) {
    @include font-size(rem(16), rem(22));
  }

  @include breakpoint(xxl) {
    @include font-size(rem(18), rem(24));
  }
}

%headline{
  @include font-size(rem(14), rem(21));
  font-weight: 600;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: $secondary-01;
  margin-bottom: rem(10);
  @include breakpoint(sm) {
    margin-bottom: rem(20);
  }
}
