%p{
  @include font-size(rem(17), rem(25));
  font-weight: normal;
  letter-spacing: rem(0.1);

  @include breakpoint(xxl) {
    @include font-size(rem(19), rem(29));
  }
}

%p-n{
  @include font-size(rem(17), rem(25));
  font-weight: normal;
  letter-spacing: rem(0.1);
}


%p-l{
  @include font-size(rem(21), rem(36));
  font-weight: normal;
  @include breakpoint(sm){
    @include font-size(rem(24), rem(41));
  }
}

%p-m{
  @include font-size(rem(19), rem(28));
  font-weight: normal;
  @include breakpoint(sm) {
    @include font-size(rem(20), rem(30));
  }
}

%p-s{
  @include font-size(rem(15), rem(20));
  font-weight: normal;
  letter-spacing: rem(0.1);

  @include breakpoint(xxl) {
    @include font-size(rem(17), rem(23));
  }
}
