$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "../../static/fonts" !default;

$icon-pause: "\e94c";
$icon-param: "\e94b";
$icon-assignment: "\e949";
$icon-quote1: "\e94a";
$icon-document: "\e946";
$icon-pdf: "\e947";
$icon-photo: "\e948";
$icon-callback: "\e944";
$icon-phonecall: "\e945";
$icon-quote: "\e943";
$icon-gnv: "\e942";
$icon-add: "\e900";
$icon-addlocation: "\e901";
$icon-arrowback: "\e902";
$icon-arrowbotom: "\e903";
$icon-arrowright: "\e904";
$icon-arrowup: "\e905";
$icon-avatar: "\e906";
$icon-bullet: "\e907";
$icon-cards: "\e908";
$icon-chat: "\e909";
$icon-check: "\e90a";
$icon-check-filled: "\e90b";
$icon-close: "\e90c";
$icon-comments: "\e90d";
$icon-creditcard: "\e90e";
$icon-date: "\e90f";
$icon-date-1: "\e910";
$icon-delete: "\e911";
$icon-doc: "\e912";
$icon-download: "\e913";
$icon-edit: "\e914";
$icon-electric: "\e915";
$icon-electricmeter: "\e916";
$icon-euros: "\e917";
$icon-eye: "\e918";
$icon-eye-blind: "\e919";
$icon-facebook: "\e91a";
$icon-filter: "\e91b";
$icon-home: "\e938";
$icon-info: "\e939";
$icon-instagram: "\e93a";
$icon-leaf: "\e93b";
$icon-lightbulb: "\e93c";
$icon-link: "\e93d";
$icon-linkedin: "\e93e";
$icon-list: "\e93f";
$icon-mail: "\e940";
$icon-menu: "\e941";
$icon-miniarrowbottom: "\e91c";
$icon-miniarrowleft: "\e91d";
$icon-miniarrowright: "\e91e";
$icon-miniarrowup: "\e91f";
$icon-minicards: "\e920";
$icon-minimize: "\e921";
$icon-more: "\e922";
$icon-mylocation: "\e923";
$icon-navigation: "\e924";
$icon-partners: "\e925";
$icon-phone: "\e926";
$icon-pinterest: "\e927";
$icon-place: "\e928";
$icon-play: "\e929";
$icon-plug: "\e92a";
$icon-question: "\e92b";
$icon-reload: "\e92c";
$icon-schedule: "\e92d";
$icon-search: "\e92e";
$icon-searchfournisseur: "\e92f";
$icon-searchinfo: "\e930";
$icon-searchinstal: "\e931";
$icon-services: "\e932";
$icon-share: "\e933";
$icon-touch: "\e934";
$icon-twitter: "\e935";
$icon-warning: "\e936";
$icon-work: "\e937";


