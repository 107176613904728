html{
    @extend %font-primary-regular;
    font-size: rem(14);
    line-height: rem(18);
    text-align: left;
    color: $text-color;
}

strong, b{
    @extend %b;
}

em{
    @extend %i;
}

u{
    @extend %u;
}

p{
    @extend %p;
    //extends does not work
    @include font-size(rem(17), rem(28));
    font-weight: normal;

    &.introduction{

    }
}

a{
    text-decoration: none;
}

.p-l{
    @extend %p-l;
}


.p-m{
    @extend %p-m;
}

.p-s{
    @extend %p-s;
}

.txt-gradient {
    @include text-gradient;
}
