%readmore {
  color: $secondary-02;
  font-weight: 600;
  position: relative;
  padding-right: rem(30);
  cursor: pointer;
  @include font-size(rem(16), rem(29));
  @include transition-default;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  &:before{
    position: absolute;
    right: 0;
    top: 0;
    @include icon($icon-miniarrowright, $secondary-02);
    @include transition-default;
  }
  &:hover{
    color: $secondary-01;
    &:before{
      color: $secondary-01;
    }
  }
}

%readmore-disable{
  @extend %readmore;
  color:$gray-02;
  &:before{
    color:$gray-02;
  }

  &:hover{
    color: #D3D8DC;
    &:before{
      color: #D3D8DC;
    }
  }
}

%a {
  color: $secondary-01;
  text-decoration: underline;
}
