%primary-list {
  position: relative;
  color: $gray-01;
  @include font-size(rem(17), rem(28));
  padding-left: rem(25);
  &:not(:last-child){
    padding-bottom: rem(15);
  }
  &:before {
    @include icon($icon-miniarrowright, $secondary-02, rem(24));
    position: absolute;
    top: 0;
    left: -0.25em;
  }
}

%number-list {
  position: relative;
  padding-left: rem(30);
  counter-increment: step;
  color: $primary-color;
  @include font-size(rem(17), rem(27));
  color: $primary-color;
  &:not(:last-child) {
    padding-bottom: rem(15);
  }
  &:before {
    content: counters(step, ". ", decimal-leading-zero);
    position: absolute;
    top: 0;
    left: 0;
    font-weight: bold;
    color: $secondary-color;
    font-size: rem(17);
  }
  &:after {
    content: '.';
    position: absolute;
    left: rem(20);
    top: 0;
    font-weight: bold;
    color: $secondary-color;
  }
}
