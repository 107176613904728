/* ===================
      Wysiwyg
/* ===================*/
.wysiwyg{
  //****************MARGIN**********************//
  //last element not margin
  & > *:last-child{
    margin-bottom: 0;
  }

  p, ul, ol{
    & + h2, + h3, +h4, +h5, +h6 {
      margin-top: rem(30);
    }
  }

  h2{
    & + h3, + h4, +h5, +h6{
      margin-top: rem(10);
    }
  }
  h3{
    & + h2, + h4, +h5, +h6{
      margin-top: rem(5);
    }
  }
  h4{
    & + h3, + h2, +h5, +h6{
      margin-top: rem(10);
    }
  }

  h5, h6 {
    & + h1, + h2, + h3, + h4 {
      margin-top: rem(10);
    }
  }

  h2, h3, h4, h5, h6{
    & + ul, + ol, + p{
      margin-top: 10px;
      @include breakpoint(md){
        margin-top: rem(20);
      }
    }
  }

  p{
    & + ul, + ol{
      margin-top: rem(10);
    }
  }

  ul{
    & + ul, + ol{
      margin-top: rem(10);
    }
    & + p{
      margin-top: rem(20);
    }
  }

  ol{
    & + ol, + ul{
      margin-top: rem(10);
    }
    & + p{
      margin-top: rem(20);
    }
  }

  //****************CUSTOMIZE**********************//
  h1{
    @extend %h1;
  }

  h2 {
    @extend %h2;
  }

  h3 {
    @extend %h3;
  }

  h4 {
    @extend %h4;
  }

  h5 {
    @extend %h5;
  }

  h6 {
    @extend %h6;
  }

  ul{
    li{
      @extend %primary-list;
    }
  }

  ol{
    li{
      @extend %number-list;
    }
  }

  p{
    @extend %p;
    @include font-size(rem(17), rem(28));
    @include breakpoint(xxl) {
      @include font-size(rem(19), rem(29));
    }
    font-weight: normal;
    & + p {
      margin-top: rem(20);
    }
  }

  a{
    &:not(.btn) {
      @extend %a;
      text-decoration: underline;
      @include transition-default;
      &:hover{
        color: $tertiary-color;
      }
    }
  }

  hr{
    display: none;
  }

  table {
    @extend p;
    border-collapse: collapse;
    margin: rem(24) 0;
    font-size: 0.9em;
    border-top-left-radius: rem(8);
    border-top-right-radius: rem(8);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);

    @include breakpoint-max(md) {
      display: block;
      overflow: scroll;
      white-space: nowrap;
    }

    thead tr {
      background-color: $primary-color;
      color: #ffffff;
      text-align: left;
    }

    th, td {
      padding: rem(12) rem(15);
    }

    tbody tr {
      border-bottom: rem(1) solid #dddddd;
    }

    tbody tr:nth-of-type(even) {
      background-color: #f3f3f3;
    }

    tbody tr:last-of-type {
      border-bottom: rem(2) solid $secondary-color;
    }

  }
}

.on-dark{
  h1, h2, h3, h4, h5, h6, p, ul li, ol li, .headline, a, div, span{
    color: $white;
  }

  @include breakpoint(md) {
    .btn--primary {
      &:hover {
        background: $white;
        border-color: $white;
        color: $secondary-01;
      }
    }
  }

  .btn--outline {
    border-color: $white;
    color: $white;
    @include breakpoint(md) {
      background: $white;
      color: $secondary-01;
    }
  }
}
