@import "../fonts/font-hind-vadodara";
@import "../fonts/font-icomoon";
@import "../fonts/var-icomoon";
@import "../fonts/font-icomoon-linear";
@import "../fonts/var-icomoon-linear";
@import "~dauphine/src/mixins/typography";

/* ===================
	    Colors
/* ===================*/

@function primary-color($opacity : 1) {
  @return rgba(28, 26, 54, $opacity);
}

@function white($opacity : 1) {
  @return rgba(255, 255, 255, $opacity);
}

@function gray-03($opacity : 1) {
  @return rgba(199, 203, 207, $opacity);
}

$primary-color      : primary-color();
$primary-color-dark : $primary-color;
$primary-black      : #202956;
$secondary-color    : #253A9F;
$secondary-01       : $secondary-color;
$secondary-02       : #009550;
$secondary-03       : #026185;
$tertiary-color     : $secondary-02;
$text-color         : $primary-black;
$success-color      : #93CB1C;
$warning-color      : #F4841C;

$white              : #fff;
$gray-01            : #626886;
$gray-02            : #9297B0;
$gray-03            : #D3D3D3;
$gray-04            : #F0F0F0;
$beige              : #FAF8F6;
$danger             : #D11149;

$overlay-color      : #bbb;
$line-color         : #bbb;
$border-white       : rgba(255, 255, 255, 0.35);

$gradient-primary   : linear-gradient(262.8deg, #364490 9.59%, #035E85 50.74%, #007F70 73.49%, #008F57 96.7%);
$gradient-primary-h : linear-gradient(67.58deg, #54388B 24.14%, #554894 35.83%, #5773AC 58.78%, #5AB7D3 90.49%, #5AC0D8 94.61%, #58C0D8 94.93%);
$gradient-secondary : linear-gradient(225deg, #273889 0%,  #111a45 100%);
$image-overlay      : linear-gradient(230deg, rgba($white, 0.7), transparent);

$box-shadow         : 0px 6px 80px rgba(0, 0, 0, 0.03), 0px 30px 60px rgba(0, 0, 0, 0.03);
$box-shadow-hover   : 0px 5px 10px rgba(0, 0, 0, 0.06), 0px 10px 60px rgba(0, 0, 0, 0.06);
$box-shadow-active   : 0px 5px 10px rgba(0, 0, 0, 0.12), 0px 10px 10px rgba(0, 0, 0, 0.12);


$side-shadow : rem(-40) rem(-40) rem(100) rem(-80) #0A2B38, rem(40) rem(-40) rem(100) rem(-80) #0A2B38;

/* ===================
	    Fonts
/* ===================*/
$font-primary : 'Hind Vadodara', -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, sans-serif;
$font-secondary : 'Hind Vadodara', -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, sans-serif;
$font-icon : 'icomoon';

/* ===================
    Margin & padding
/* ===================*/
$paddings : (
  s : rem(4),
  m : rem(8),
  l : rem(12)
);

@function padding($varname) {
  @return map-get($paddings, $varname);
};
/* ===================
    Config
/* ===================*/
$debug-rem : true;


/* ===================
    Dauphine var default
/* ===================*/
$columns: true;
$sizing: true;

$grid: false;
$order: false;
$direction: false;
$display: false;
$spacing: false;

$grid-breakpoints: (
  xs: 0,
  xsm: 480px, // phone-landscape
  sm: 720px, // tablet-portrait
  md: 960px, // tablet-landscape
  lg: 1280px, // desktop
  xlg: 1600px, // desktop large
  xxl: 1920px
);

// Grid containers
$container-max-widths: (
  sm: 720px,
  md: 940px,
  lg: 1200px,
  xlg: 1400px,
  xxl: 1800px
);

/* ===================
    FlexContent
/* ===================*/
$paddingFlex: (
      s : rem(40),
      m : rem(60),
      l : rem(80)
);

@function paddingFlex($varname) {
  @return map-get($paddingFlex, $varname);
};
/* ===================
    Animation
/* ===================*/

$animTranslation : 60px;
$translateTop : translate3d(0, $animTranslation, 0);
$animCurbe : cubic-bezier(0, 0, 0.58, 1);
$animTime : 400ms;

$anim-cubic-2: cubic-bezier(0.16, 0.84, 0.44, 1);
$anim-curbic-time-2: 400ms;
