.btn{
  @extend %btn;
  &--primary{
      @extend %btn--primary;
    @include breakpoint(md) {
      &:hover {
        background: $primary-black;
        border-color: $primary-black;
      }
    }
  }
  &--small{
      @extend %btn--small;
    display: inline-flex;
      &:after {
        @include icon($icon-miniarrowright, inherit, rem(24));
        transform: translateY(0.05em);
      }
      @include breakpoint(md) {
        &:hover {
          color: $primary-black;
        }
      }
  }
  &--outline{
      @extend %btn--outline;
    @include breakpoint(md) {
      &:hover {
          border-color: $primary-black;
          color: $primary-black;
        }
      }
  }
  &--disabled {
    &.btn--primary, &.btn--outline {
      color: $gray-02;
      background: $gray-03;
      border-color: $gray-03;
    }
    &.btn--small {
      color: $gray-02;
    }
    @include breakpoint(md) {
      &:hover {
        opacity: 0.5;
        cursor: initial;
      }
    }
  }
}
