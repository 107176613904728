.home-online-services {
	margin-top: -46px;
	z-index: 2;
	position: relative;

	&__wrapper {
		.btn-show-more {
			display: table;
			margin: 0 auto;
		}
	}

	&__list {
		z-index: 1;
		@include flex-row;
		margin-bottom: rem(8); //-16 + 24
		@include breakpoint(md) {
			margin-bottom: rem(22); //44-26
		}

		.hidden-services {
			display: flex;
			flex-wrap: wrap;
			width: 100%;

			&.hidden {
				display: none;
			}
		}

		.card-wrapper {
			margin-bottom: rem(16);
			@include col(12);
			@include breakpoint(sm) {
				@include col(6);
			}
			@include breakpoint(md) {
				@include col(3);
				margin-bottom: rem(26);
			}

			.gie-services__card {
				height: 100%;
				flex-direction: row;
				align-items: center;
				justify-content: flex-start;
				@include breakpoint(sm) {
					justify-content: space-between;
					flex-direction: column;
				}
				@include breakpoint(md) {
					padding:rem(30);
				}
				@include breakpoint(lg) {
					padding: rem(45) rem(45) rem(20)  rem(45);
				}
				.card__text{
					margin : rem(10) 0;
					@include breakpoint-max(sm) {
						margin-left: rem(15);
					}
					@include breakpoint(lg) {
						margin-top: rem(30);
						margin-bottom: rem(40);
					}
				}
				.card__btn{
					@include breakpoint-max(sm) {
						display: none;
					}
				}
			}
		}

	}
}
