@import "./var-icomoon";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?7xdwvy');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?7xdwvy#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?7xdwvy') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?7xdwvy') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?7xdwvy##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
}

.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-pause {
  &:before {
    content: $icon-pause;
  }
}
.icon-param {
  &:before {
    content: $icon-param;
  }
}

.icon-assignment {
  &:before {
    content: $icon-assignment;
  }
}

.icon-quote1 {
  &:before {
    content: $icon-quote1;
  }
}
.icon-document {
  &:before {
    content: $icon-document;
  }
}
.icon-pdf {
  &:before {
    content: $icon-pdf;
  }
}
.icon-photo {
  &:before {
    content: $icon-photo;
  }
}

.icon-callback {
  &:before {
    content: $icon-callback;
  }
}
.icon-phonecall {
  &:before {
    content: $icon-phonecall;
  }
}

.icon-quote {
  &:before {
    content: $icon-quote;
  }
}

.icon-gnv {
  &:before {
    content: $icon-gnv;
  }
}
.icon-add {
  &:before {
    content: $icon-add;
  }
}
.icon-addlocation {
  &:before {
    content: $icon-addlocation;
  }
}
.icon-arrowback {
  &:before {
    content: $icon-arrowback;
  }
}
.icon-arrowbotom {
  &:before {
    content: $icon-arrowbotom;
  }
}
.icon-arrowright {
  &:before {
    content: $icon-arrowright;
  }
}
.icon-arrowup {
  &:before {
    content: $icon-arrowup;
  }
}
.icon-avatar {
  &:before {
    content: $icon-avatar;
  }
}
.icon-bullet {
  &:before {
    content: $icon-bullet;
  }
}
.icon-cards {
  &:before {
    content: $icon-cards;
  }
}
.icon-chat {
  &:before {
    content: $icon-chat;
  }
}
.icon-check {
  &:before {
    content: $icon-check;
  }
}
.icon-check-filled {
  &:before {
    content: $icon-check-filled;
  }
}
.icon-close {
  &:before {
    content: $icon-close;
  }
}
.icon-comments {
  &:before {
    content: $icon-comments;
  }
}
.icon-creditcard {
  &:before {
    content: $icon-creditcard;
  }
}
.icon-date {
  &:before {
    content: $icon-date;
  }
}
.icon-date-1 {
  &:before {
    content: $icon-date-1;
  }
}
.icon-delete {
  &:before {
    content: $icon-delete;
  }
}
.icon-doc {
  &:before {
    content: $icon-doc;
  }
}
.icon-download {
  &:before {
    content: $icon-download;
  }
}
.icon-edit {
  &:before {
    content: $icon-edit;
  }
}
.icon-electric {
  &:before {
    content: $icon-electric;
  }
}
.icon-electricmeter {
  &:before {
    content: $icon-electricmeter;
  }
}
.icon-euros {
  &:before {
    content: $icon-euros;
  }
}
.icon-eye {
  &:before {
    content: $icon-eye;
  }
}
.icon-eye-blind {
  &:before {
    content: $icon-eye-blind;
  }
}
.icon-facebook {
  &:before {
    content: $icon-facebook;
  }
}
.icon-filter {
  &:before {
    content: $icon-filter;
  }
}
.icon-home {
  &:before {
    content: $icon-home;
  }
}
.icon-info {
  &:before {
    content: $icon-info;
  }
}
.icon-instagram {
  &:before {
    content: $icon-instagram;
  }
}
.icon-leaf {
  &:before {
    content: $icon-leaf;
  }
}
.icon-lightbulb {
  &:before {
    content: $icon-lightbulb;
  }
}
.icon-link {
  &:before {
    content: $icon-link;
  }
}
.icon-linkedin {
  &:before {
    content: $icon-linkedin;
  }
}
.icon-list {
  &:before {
    content: $icon-list;
  }
}
.icon-mail {
  &:before {
    content: $icon-mail;
  }
}
.icon-menu {
  &:before {
    content: $icon-menu;
  }
}
.icon-miniarrowbottom {
  &:before {
    content: $icon-miniarrowbottom;
  }
}
.icon-miniarrowleft {
  &:before {
    content: $icon-miniarrowleft;
  }
}
.icon-miniarrowright {
  &:before {
    content: $icon-miniarrowright;
  }
}
.icon-miniarrowup {
  &:before {
    content: $icon-miniarrowup;
  }
}
.icon-minicards {
  &:before {
    content: $icon-minicards;
  }
}
.icon-minimize {
  &:before {
    content: $icon-minimize;
  }
}
.icon-more {
  &:before {
    content: $icon-more;
  }
}
.icon-mylocation {
  &:before {
    content: $icon-mylocation;
  }
}
.icon-navigation {
  &:before {
    content: $icon-navigation;
  }
}
.icon-partners {
  &:before {
    content: $icon-partners;
  }
}
.icon-phone {
  &:before {
    content: $icon-phone;
  }
}
.icon-pinterest {
  &:before {
    content: $icon-pinterest;
  }
}
.icon-place {
  &:before {
    content: $icon-place;
  }
}
.icon-play {
  &:before {
    content: $icon-play;
  }
}
.icon-plug {
  &:before {
    content: $icon-plug;
  }
}
.icon-question {
  &:before {
    content: $icon-question;
  }
}
.icon-reload {
  &:before {
    content: $icon-reload;
  }
}
.icon-schedule {
  &:before {
    content: $icon-schedule;
  }
}
.icon-search {
  &:before {
    content: $icon-search;
  }
}
.icon-searchfournisseur {
  &:before {
    content: $icon-searchfournisseur;
  }
}
.icon-searchinfo {
  &:before {
    content: $icon-searchinfo;
  }
}
.icon-searchinstal {
  &:before {
    content: $icon-searchinstal;
  }
}
.icon-services {
  &:before {
    content: $icon-services;
  }
}
.icon-share {
  &:before {
    content: $icon-share;
  }
}
.icon-touch {
  &:before {
    content: $icon-touch;
  }
}
.icon-twitter {
  &:before {
    content: $icon-twitter;
  }
}
.icon-warning {
  &:before {
    content: $icon-warning;
  }
}
.icon-work {
  &:before {
    content: $icon-work;
  }
}

