
.linear-icon-outline {
  @extend .linear-icon;
  font-size: rem(80);
  border-radius: 50%;
  background: $white;
  position: relative;
  //border: 1px solid $secondary-02;

  z-index: 1;

  &:before {
    background: $white;
    border-radius: 50%;
  }

  &:after {
    content: "";
    z-index: -1;
    position: absolute;
    background-image: repeating-conic-gradient($secondary-02 0, $secondary-02 1.432deg, $white 1.432deg, $white 5.730deg);
    height: calc(100% + 2px);
    width: calc(100% + 2px);
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@mixin outline-rounded-icon($color) {
  font-size: rem(24);
  color: $color;
  border: 1px solid $color;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
}
