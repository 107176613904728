$icomoon-linear-font-family: "icomoon-linear" !default;
$icomoon-linear-font-path: "../../static/fonts" !default;

$linear-icon-card-change: "\e900";
$linear-icon-card-sun: "\e901";
$linear-icon-card-man: "\e902";
$linear-icon-card-envelop: "\e903";
$linear-icon-card-delivery: "\e904";
$linear-icon-card-contrat: "\e905";
$linear-icon-card-box: "\e906";
$linear-icon-calendar: "\e907";
$linear-icon-card-euro: "\e908";
$linear-icon-card-tools: "\e909";
$linear-icon-card-wheel: "\e90a";
$linear-icon-contract: "\e90b";
$linear-icon-devis: "\e90c";
$linear-icon-documents: "\e90d";
$linear-icon-fiches: "\e90e";
$linear-icon-handshake: "\e90f";
$linear-icon-user: "\e910";
$linear-icon-video: "\e911";
$linear-icon-warning: "\e912";
