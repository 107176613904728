@function padding($varname) {
    @return map-get($paddings, $varname);
};
// using exemple => padding : padding(s);

.container {
  @include breakpoint(xxl) {
    max-width: 1800px;
  }
}
