@import "./var-icomoon-linear";

@font-face {
	font-family: '#{$icomoon-linear-font-family}';
	src: url('#{$icomoon-linear-font-path}/#{$icomoon-linear-font-family}.eot?yulvai');
	src: url('#{$icomoon-linear-font-path}/#{$icomoon-linear-font-family}.eot?yulvai#iefix') format('embedded-opentype'),
	url('#{$icomoon-linear-font-path}/#{$icomoon-linear-font-family}.ttf?yulvai') format('truetype'),
	url('#{$icomoon-linear-font-path}/#{$icomoon-linear-font-family}.woff?yulvai') format('woff'),
	url('#{$icomoon-linear-font-path}/#{$icomoon-linear-font-family}.svg?yulvai##{$icomoon-font-family}') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

.linear-icon {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: '#{$icomoon-linear-font-family}' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.linear-icon-card-change {
	&:before {
		content: $linear-icon-card-change;
		color: #253a9f;
	}
}

.linear-icon-card-sun {
	&:before {
		content: $linear-icon-card-sun;
		color: #253a9f;
	}
}

.linear-icon-card-man {
	&:before {
		content: $linear-icon-card-man;
		color: #253a9f;
	}
}

.linear-icon-card-envelop {
	&:before {
		content: $linear-icon-card-envelop;
		color: #253a9f;
	}
}

.linear-icon-card-delivery {
	&:before {
		content: $linear-icon-card-delivery;
		color: #253a9f;
	}
}

.linear-icon-card-contrat {
	&:before {
		content: $linear-icon-card-contrat;
		color: #253a9f;
	}
}

.linear-icon-card-box {
	&:before {
		content: $linear-icon-card-box;
		color: #253a9f;
	}
}

.linear-icon-card-calendar {
	&:before {
		content: $linear-icon-calendar;
		color: #253a9f;
	}
}

.linear-icon-card-euro {
	&:before {
		content: $linear-icon-card-euro;
		color: #253a9f;
	}
}

.linear-icon-card-tools {
	&:before {
		content: $linear-icon-card-tools;
		color: #253a9f;
	}
}

.linear-icon-card-wheel {
	&:before {
		content: $linear-icon-card-wheel;
		color: #253a9f;
	}
}

.linear-icon-contract {
	&:before {
		content: $linear-icon-contract;
		color: #253a9f;
	}
}

.linear-icon-devis {
	&:before {
		content: $linear-icon-devis;
		color: #253a9f;
	}
}

.linear-icon-documents {
	&:before {
		content: $linear-icon-documents;
		color: #253a9f;
	}
}

.linear-icon-fiches {
	&:before {
		content: $linear-icon-fiches;
		color: #253a9f;
	}
}

.linear-icon-handshake {
	&:before {
		content: $linear-icon-handshake;
		color: #253a9f;
	}
}

.linear-icon-user {
	&:before {
		content: $linear-icon-user;
		color: #253a9f;
	}
}

.linear-icon-video {
	&:before {
		content: $linear-icon-video;
		color: #253a9f;
	}
}

.linear-icon-warning {
	&:before {
		content: $linear-icon-warning;
		color: #253a9f;
	}
}
